import type { GatsbyBrowser, RouteUpdateArgs } from 'gatsby';

export interface IGatsbyBrowser extends GatsbyBrowser {
  onRouteUpdate?(args: RouteUpdateArgs & {
    location: RouteUpdateArgs['location'] & {
      state: Record<string, any>;
    };
  }): void;
}


export const onRouteUpdate: IGatsbyBrowser['onRouteUpdate'] = async () => {

  if (process.env.NODE_ENV === 'development') {
    const redirectPaths = [
      '/',
    ];

    const url = new URL(window.location.href);
    if (redirectPaths.includes(url.pathname)) {
      window.location.replace('/en-us');
    }
  }
};
