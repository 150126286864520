import axios from 'axios';

import { getCookieValue } from '@klv/shared/utils/cookies';

interface IAccountAttributes {
  account: boolean;
  accountDataLoaded: boolean;
  loggedIn: boolean;
  paid?: boolean;
  landingPageUrl: string;
}

async function getLoggedInStatus() {
  const axiosInstance = axios.create();
  const accountAttrs: IAccountAttributes = {
    account: false,
    accountDataLoaded: false,
    loggedIn: false,
    landingPageUrl: '',
  };
  try {

    // make it easier to test logged in and logged out states.
    let testAuthenticationPath = '/dev-test-authentication-logged-out.json';
    if (process.env.NODE_ENV !== 'production') {
      const queryString = (typeof window !== 'undefined') ? window.location.search : '';
      if ((new URLSearchParams(queryString)).get('logged_in') !== null) {
        testAuthenticationPath = '/dev-test-authentication-logged-in.json';
      }
    }
    
    const urlBase = (process.env.NODE_ENV === 'production') ? 'https://www.klaviyo.com' : '';
    const authenticationUrl = (process.env.NODE_ENV === 'production') ? `${urlBase}/ajax/public/site` : testAuthenticationPath;
    const response = await axiosInstance.get(`${authenticationUrl}`, { withCredentials: true });
    const { status } = response;
    if (status === 200) {
      if (response?.data && response?.data?.data && response?.data?.data?.user) {
        accountAttrs.account = true;
        accountAttrs.loggedIn = true;
        accountAttrs.paid = response.data.data.is_paid;
        accountAttrs.accountDataLoaded = true;
        accountAttrs.landingPageUrl = `${urlBase}${response.data.data.landing_page_url}`;
      } else {
        const getCookieValueKlaId = getCookieValue('__kla_id');
        if (getCookieValueKlaId) {
          const decodedKlaId = JSON.parse(atob(getCookieValueKlaId));
          if (decodedKlaId) {
            accountAttrs.accountDataLoaded = true;
          }
          if (decodedKlaId && decodedKlaId.AccountID) {
            accountAttrs.account = true;
          }
        }
      }

      return accountAttrs;
    } if (status === 404) {
      throw new Error('Route Not Found');
    } else {
      throw new Error('Unexpected status code received.');
    }
  } catch (error) {
    return false;
  }
}

export default getLoggedInStatus;
