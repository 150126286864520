import { createContext, useState, useEffect } from 'react';
import type { ReactNode } from 'react';

import type { IUserContext } from './types';

export const UserContext = createContext<IUserContext>({ isUserLoggedIn: false });


export const UserContextProvider = ({ children }: { children: ReactNode; }) => {

  const [userState, setUserState] = useState({});
  const [viewedResources, setViewedResources] = useState<string[]>([]);

  // Update state based on when session storage changes
  useEffect(() => {

    // set the initial state of the user from session storage
    const initialUserState = JSON.parse(sessionStorage.getItem('skilljarUser') || '{}');
    setUserState(initialUserState);

    const initialViewedResources = JSON.parse(localStorage.getItem('viewedResources') || '[]');
    setViewedResources(initialViewedResources);

    const userEventListener = (_event: Event) => {
      setUserState(JSON.parse(sessionStorage.getItem('skilljarUser') || '{}'));
    };
    window.addEventListener('skillJarUserUpdated', userEventListener);

    return () => {
      window.removeEventListener('skillJarUserUpdated', userEventListener);
    };
  }, []);

  // Update localStorage when viewedResources changes
  useEffect(() => {
    localStorage.setItem('viewedResources', JSON.stringify(viewedResources));
  }, [viewedResources]);

  const trackViewedResource = (resourceId: string) => {
    if (!viewedResources.includes(resourceId)) {
      setViewedResources([...viewedResources, resourceId]);
    }
  };

  return (
    <UserContext.Provider value={{
      skilljarUser: userState as IUserContext['skilljarUser'],
      isUserLoggedIn: Object.keys(userState).length > 0,
      viewedResources,
      trackViewedResource,
    }}>
      {children}
    </UserContext.Provider>
  );
};
